<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import {
	DropdownMenuCheckboxItem,
	type DropdownMenuCheckboxItemEmits,
	type DropdownMenuCheckboxItemProps,
	DropdownMenuItemIndicator,
	useForwardPropsEmits,
} from 'radix-vue';
import { PhCheck } from '@phosphor-icons/vue';
import { cn } from '@laam/lib/utils';

const props = defineProps<
	DropdownMenuCheckboxItemProps & {
		class?: HTMLAttributes['class'];
		variant?: string;
		bgWhite?: boolean;
	}
>();
const emits = defineEmits<DropdownMenuCheckboxItemEmits>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
	<DropdownMenuCheckboxItem
		v-if="variant != null && variant === 'secondary'"
		v-bind="forwarded"
		:class="
			cn(
				'relative flex cursor-default select-none items-center rounded-small py-sm pl-8xl pr-xs text-sm outline-none transition-colors  data-[disabled]:pointer-events-none data-[disabled]:opacity-100 ',
				props.class,
			)
		"
	>
		<span class="absolute left-md flex h-2xl w-2xl items-center">
			<DropdownMenuItemIndicator>
				<PhCheck class="w-3xl h-3xl" />
			</DropdownMenuItemIndicator>
		</span>
		<slot />
	</DropdownMenuCheckboxItem>
	<DropdownMenuCheckboxItem
		v-else
		v-bind="forwarded"
		class="dropdown-menu--checkbox-item"
		:class="cn('flex items-center px-sm py-[1px] w-full', props.class)"
	>
		<div
			class="p-lg gap-lg flex items-center flex-1 justify-between"
			:class="{
				'bg-[#F0F0F0] rounded-small': forwarded.checked && !bgWhite,
			}"
		>
			<slot name="body" />

			<DropdownMenuItemIndicator>
				<PhCheck class="w-5xl h-5xl" />
			</DropdownMenuItemIndicator>
		</div>
	</DropdownMenuCheckboxItem>
</template>
